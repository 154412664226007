import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';

interface FooterPosition {
    y: number
}

@InjectModule({ stateFactory: true }, module)
export default class FooterModule extends InjectVuexModule {
    public staticFooter: FooterPosition = {
        y: 0,
    };

    public footer_line_1: string = "";
    public footer_line_2: string = "";
    public footer_line_3: string = "";
    public footer_line_4: string = "";
    public footer_line_5: string = "";
    public footer_tnc: string = "";
    public footer_logo: string = "";
    public footer_logo_class: string = "";

    @Mutation
    public setStaticFooter(footerPosition: FooterPosition): void {
        Object.assign(this.staticFooter, footerPosition)
    }

    @Mutation
    public setFooterLine1(content: string): void {
        this.footer_line_1 = content;
    }

    @Mutation
    public setFooterLine2(content: string): void {
        this.footer_line_2 = content;
    }

    @Mutation
    public setFooterLine3(content: string): void {
        this.footer_line_3 = content;
    }

    @Mutation
    public setFooterLine4(content: string): void {
        this.footer_line_4 = content;

    }
    @Mutation
    public setFooterLine5(content: string): void {
        this.footer_line_5 = content;
    }

    @Mutation
    public setFooterTnC(content: string): void {
        this.footer_tnc = content;
    }

    @Mutation
    public setFooterLogo(content: string): void {
        this.footer_logo = content;
    }

    @Mutation
    public setFooterLogoClass(content: string): void {
        this.footer_logo_class = content;
    }
}
