export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    name_title: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    footerLogoTukysa: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyBreastCancerId: string;
    surveyColorectalCancerId: string;

    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswer: string;

    eligibilityPrescribeQuestion: string;
    eligibilityPrescribeAnswer: string;

    eligibilityCommercialInsuranceQuestion: string;
    eligibilityCommercialInsuranceAnswer: string;

    eligibilityPublicInsuranceQuestion: string;
    eligibilityPublicInsuranceAnswer: string;

    eligibilityDiagnoseQuestion: string;
    eligibilityDiagnoseAnswer: string;

    eligibilityTucatinibQuestion : string;
    eligibilityTucatinibAnswer: string;
    eligibilityTucatinibAnswerNo: string;

    eligibilityKeytrudaQuestion : string;
    eligibilityKeytrudaAnswer : string;

    eligibilityReceivedHER2Question: string;
    eligibilityReceivedHER2Answer: string;

    eligibilityCitizenColorectalQuestion: string;
    eligibilityCitizenColorectalAnswer: string;

    eligibilityPrescribeColorectalQuestion: string;
    eligibilityPrescribeColorectalAnswer: string;

    eligibilityCommercialInsuranceColorectalQuestion: string;
    eligibilityCommercialInsuranceColorectalAnswer: string;

    eligibilityPublicInsuranceColorectalQuestion: string;
    eligibilityPublicInsuranceColorectalAnswer: string;

    eligibilityDiagnosedMetastaticQuestion: string;
    eligibilityDiagnosedMetastaticAnswer: string;

    eligibilityReceivedRegimensMetastaticQuestion: string;
    eligibilityReceivedRegimensMetastaticAnswer: string;

    eligibilityCombinatinDrugQuestion: string;
    eligibilityCombinatinDrugAnswer: string;



    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    hideTivdak: string;
    hideNewIndicator: string;

    eligibilityDiagnosedMetastaticReEnrollQuestion: string;
    eligibilityDiagnosedMetastaticReEnrollAnswer: string;

    shouldShowBanner: string;
}
