import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f1d593d2 = () => interopDefault(import('..\\pages\\adcetris-landing.vue' /* webpackChunkName: "pages_adcetris-landing" */))
const _2525e750 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _76903e5e = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _c119aa9c = () => interopDefault(import('..\\pages\\re-enroll\\index.vue' /* webpackChunkName: "pages_re-enroll_index" */))
const _bc977c4e = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _8bfea448 = () => interopDefault(import('..\\pages\\selection.vue' /* webpackChunkName: "pages_selection" */))
const _17d53412 = () => interopDefault(import('..\\pages\\tivdak-landing.vue' /* webpackChunkName: "pages_tivdak-landing" */))
const _0b7e2e32 = () => interopDefault(import('..\\pages\\tukysa-landing.vue' /* webpackChunkName: "pages_tukysa-landing" */))
const _2e14fb24 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _3a05a0f9 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _3a13b87a = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _3a2fe77c = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _3a3dfefd = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _6eebdc3a = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _03131fba = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _304da611 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _27f16573 = () => interopDefault(import('..\\pages\\need-a-card\\provider-information.vue' /* webpackChunkName: "pages_need-a-card_provider-information" */))
const _8873b8a0 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _8f3d2470 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _6f3539ed = () => interopDefault(import('..\\pages\\re-enroll\\eligibility.vue' /* webpackChunkName: "pages_re-enroll_eligibility" */))
const _368cd854 = () => interopDefault(import('..\\pages\\re-enroll\\enrollment.vue' /* webpackChunkName: "pages_re-enroll_enrollment" */))
const _92cfa47c = () => interopDefault(import('..\\pages\\re-enroll\\member-page.vue' /* webpackChunkName: "pages_re-enroll_member-page" */))
const _51504578 = () => interopDefault(import('..\\pages\\re-enroll\\patient-information.vue' /* webpackChunkName: "pages_re-enroll_patient-information" */))
const _622e14a0 = () => interopDefault(import('..\\pages\\re-enroll\\provider-information.vue' /* webpackChunkName: "pages_re-enroll_provider-information" */))
const _329b4f63 = () => interopDefault(import('..\\pages\\re-enroll\\success.vue' /* webpackChunkName: "pages_re-enroll_success" */))
const _acb223bc = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adcetris-landing",
    component: _f1d593d2,
    name: "adcetris-landing"
  }, {
    path: "/error",
    component: _2525e750,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _76903e5e,
    name: "have-a-card"
  }, {
    path: "/re-enroll",
    component: _c119aa9c,
    name: "re-enroll"
  }, {
    path: "/rejection",
    component: _bc977c4e,
    name: "rejection"
  }, {
    path: "/selection",
    component: _8bfea448,
    name: "selection"
  }, {
    path: "/tivdak-landing",
    component: _17d53412,
    name: "tivdak-landing"
  }, {
    path: "/tukysa-landing",
    component: _0b7e2e32,
    name: "tukysa-landing"
  }, {
    path: "/unlock",
    component: _2e14fb24,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _3a05a0f9,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _3a13b87a,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _3a2fe77c,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _3a3dfefd,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _6eebdc3a,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _03131fba,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/patient-information",
    component: _304da611,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/provider-information",
    component: _27f16573,
    name: "need-a-card-provider-information"
  }, {
    path: "/need-a-card/success",
    component: _8873b8a0,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _8f3d2470,
    name: "patient-patientSearch"
  }, {
    path: "/re-enroll/eligibility",
    component: _6f3539ed,
    name: "re-enroll-eligibility"
  }, {
    path: "/re-enroll/enrollment",
    component: _368cd854,
    name: "re-enroll-enrollment"
  }, {
    path: "/re-enroll/member-page",
    component: _92cfa47c,
    name: "re-enroll-member-page"
  }, {
    path: "/re-enroll/patient-information",
    component: _51504578,
    name: "re-enroll-patient-information"
  }, {
    path: "/re-enroll/provider-information",
    component: _622e14a0,
    name: "re-enroll-provider-information"
  }, {
    path: "/re-enroll/success",
    component: _329b4f63,
    name: "re-enroll-success"
  }, {
    path: "/",
    component: _acb223bc,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
