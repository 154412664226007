import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1af0ba15&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=1af0ba15&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af0ba15",
  null
  
)

export default component.exports