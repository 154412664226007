






























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
import DrugName from '~/components/DrugName.vue';
import FooterModule from '~/store/footer';

@Component<Footer>({
  components: {
    DrugName,
  },
})
export default class Footer extends Vue {
  @Resolve
  public layout!: LayoutModule;
  @Prop()
  public brkPoints!: object;
  @Prop({ default: false })
  public isFixed!: boolean;

  @Resolve
  public footer!: FooterModule;

  // @Watch('layout.configuration.programName', {deep: true})
  // public watchProgramName(programName: string) {
  //     this.layout.replaceProgramName(programName, this.layout.previousProgramName)
  // }

  public get footerInfoLinkLocation() {
    return this.layout.configuration.footerInfoLinkLocation;
  }

  public get isTopOrBottomBar() {
    return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
  }

  public get isLeftOrRightBar() {
    return ['left', 'right'].includes(this.footerInfoLinkLocation);
  }

  public get footerLogoSrc() {
    return this.layout.configuration.images.footer || this.$settings.url(this.$settings.footerLogo);
  }
}
