import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    GetBestEnrollmentV2RequestModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel,
    EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel,
    EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel,
    EnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    EnterpriseCoordinatorSharedWebAddressUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel
} from '@trialcard/apigateway.models';
import { CardExtV1Api } from '@trialcard/apigateway.client/cardExtV1Api';
import { Gender } from '@trialcard/enums';
import * as enums from '@trialcard/enums';
import moment, { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import { OnClear } from '~/services/clearModule';
import { Settings } from '~/services/settings';

import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates'
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers'
import EligibilityModule from '~/store/eligibility';

import { EdgeCouponApi } from '@trialcard/apigateway.client/edgeCouponApi';
import {
        CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramRequest,
        CouponMemberMemberNumberStatusMemberNumberStatusRequest
} from '@trialcard/apigateway.models';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public eligibility!: EligibilityModule;

    @Resolve
    public edgeCouponApi!: EdgeCouponApi;

    @Resolve
    public cardExtV1Api!: CardExtV1Api;

    alternativeEnrollment: boolean | null = null;

    isCareGiver: boolean | null = null;

    cardEnrollmentTypeEnrollOrReEnroll: string = ''

    currentDiseasePath:string = '';

    memberPageDialog = false;

    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
    };

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];

    defaultPharmacyData = [
        {
            model: {
                pharmacyName: '',
                nabp: '',
                npi: '',
                addresses: [
                    {
                        city: '',
                        state: '',
                    },
                ],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
                webAddresses: [] as EnterpriseCoordinatorSharedWebAddressUpsearchModel[]
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel[];

    defaultDiagnosisData = {
        diagnosisCodes: [] as string[]
    } as EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel;

    defaultSiteData = [
        {
            model: {
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];
    defaultExternalIds = [] as EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel[];

    defaultMemberNumber = pharmacyMN.number

    defaultExpirationDate = 'XX/XX/XXXX'

    memberNumber = this.defaultMemberNumber;
    expirationDate = this.defaultExpirationDate;
    patientInformationData = { ...this.defaultPatientInformationData };
    careGiverInformationData = { ...this.defaultCareGiverInformationData };
    prescriberData = [...this.defaultPrescriberData];
    siteData = [...this.defaultSiteData];
    contactPreferences = [...this.defaultContactPreferences];
    externalIds = [...this.defaultExternalIds];

    pharmacyData = [...this.defaultPharmacyData]
    diagnosisSessionData = this.defaultDiagnosisData;
    diagnosisColorectalSessionData = this.defaultDiagnosisData;

    @Mutation
    public setEnrollmentType(alternative: boolean) {
        this.alternativeEnrollment = alternative;
    }

    @Mutation
    public setCardEnrollmentTypeEnrollOrReEnroll(alternative: string) {
        this.cardEnrollmentTypeEnrollOrReEnroll = alternative;
    }

    @Mutation
    public setDiseasePath(value: string) {
        this.currentDiseasePath = value;
    }

    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...newPatientInformation };
    }

    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newCareGiverInformation };
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public async updateExternalIds(newExternalIds: EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel[]) {
        this.externalIds = [...newExternalIds];
    }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updatePharmacyData(newPharmacyData: UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel[]) {
        this.pharmacyData = [...newPharmacyData];
    }

    @Mutation
    public async updateDiagnosisSessionData(newDiagnosisSessionData: EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel) {
        this.diagnosisSessionData = newDiagnosisSessionData;
    }

    @Mutation
    public async updateColorectalDiagnosisSessionData(newDiagnosisSessionData: EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel) {
        this.diagnosisColorectalSessionData = newDiagnosisSessionData;
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }
    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }
    @Mutation
    public setReEnrollMemberNumber(value: string) {
        this.memberNumber = value;
    }

    @Mutation
    public setExpirationDate(expirationDate: string) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }

    @Mutation
    public setMemberPageDialog(value: boolean ) {
        this.memberPageDialog = value;
    }

    @Action({ rawError: true })
    public clearStore() {
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
        this.updateContactPreferences(this.defaultContactPreferences);
        this.updateExternalIds(this.defaultExternalIds);
        this.setReEnrollMemberNumber('');
    }

    @Action({ rawError: true })
    public clearProvider() {
        this.updatePrescribers(this.defaultPrescriberData);
        this.survey.clearSurveyData();
        this.updateSites(this.defaultSiteData);
        this.updatePharmacyData(this.defaultPharmacyData);
        this.updateDiagnosisSessionData(this.defaultDiagnosisData);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({ rawError: true })
    public async checkForExistingEnrollments() {
        const patient: GetBestEnrollmentV2RequestModel = {
            firstName: this.patientInformationData.firstName as string,
            lastName: this.patientInformationData.lastName as string,
            dateOfBirth: this.patientInformationData.dateOfBirth as Moment,
            zip: this.patientInformationData.addresses[0].zip as string,
            gender: this.patientInformationData.gender as Gender,
        };
        const response = await this.enrollmentApi.enrollmentGetBestEnrollmentsV2(patient, this.settings.programId);
        return (response && response.data && response.data.data && response.data.data) || [];
    }

    public mapToEnterpriseSiteModels(sitesRequest: any[]): UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[] {
        const siteList: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[] = [];
        let isPrimary = true;

        for (const st of sitesRequest) {
            const site = {
                canUpdate: true,
                canInsert: true,
                skipSearch: false,
                model: {
                    description: st.model.description || '',
                    accountRelationshipIsPrimary: isPrimary,
                    siteRoleType: enums.RoleType.PrescriberSite,
                    addresses: st.model.addresses || [],
                } as EnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
            };

            site.model!.phoneNumbers = [];
            site.model!.pointOfContacts = [];

            let phoneNumber = st.model.phoneNumbers.find((o: { phoneType: number; }) => o.phoneType === 1).phoneNumber;
            let faxNumber = st.model.phoneNumbers.find((o: { phoneType: number; }) => o.phoneType === 3).phoneNumber;

            if (phoneNumber) {
                site.model!.phoneNumbers.push({
                    phoneType: enums.ContactInfoType.PHONE,
                    phonePurpose: enums.ContactInfoPurposeType.UNKNOWN,
                    phoneNumber: phoneNumber,
                    extension: st.model.phoneNumberExtension || '',
                } as EnterpriseCoordinatorSharedPhoneUpsearchModel);
            }

            if (faxNumber) {
                site.model!.phoneNumbers.push({
                    phoneType: enums.ContactInfoType.FAX,
                    phonePurpose: enums.ContactInfoPurposeType.UNKNOWN,
                    phoneNumber: faxNumber,
                    extension: st.model.faxNumberExtension || '',
                } as EnterpriseCoordinatorSharedPhoneUpsearchModel);
            }

            if (phoneNumber || faxNumber) {
                site.model!.pointOfContacts.push({
                    phoneNumber: phoneNumber,
                    faxNumber: faxNumber,
                    preferredContactMethod: faxNumber ? enums.ContactInfoType.FAX : enums.ContactInfoType.PHONE,
                } as EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel);
            }
            siteList.push(site);
            isPrimary = false;
        }
        return siteList;
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contactPreferences: this.contactPreferences,
                            externalIds: this.externalIds,
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    diagnosisSessionData: this.diagnosisSessionData,
                    pharmacyData: this.pharmacyData,
                    prescriberData: this.prescriberData,
                    siteData: this.mapToEnterpriseSiteModels(this.siteData),
                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
            } as EnrollPatientRequestModel;

             // Api Call for Patient Enrollment. Creates new or returns existing enrollment details.
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.programId);
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }

    @Action({ rawError: true })
    public async submitToAPIMemberNumberStatus(value: string) {
        let response = null;
        try {
        const data = {
            memberId: value,
            programId: 1078
        } as CouponMemberMemberNumberStatusMemberNumberStatusRequest

        response = await this.edgeCouponApi.memberMemberNumberStatus(data)
        }
        catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }

    @Action({ rawError: true })
    public async submitToApiCardExtMemberNumberStatus(memberNumber: string) {
        let response = null;
        try {
            response = await this.cardExtV1Api.memberNumberStatusGet(1078, memberNumber)
        }
        catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }

}
